<template>
  <form class="form-constraints" @submit.prevent="submit()">
    <div class="wrapper">
      <div class="head">
        <div class="title">제약 편집</div>
      </div>
      <div class="body">
        <div class="row">
          <div class="part" :class="computedColClass">
            <div class="form-group mb-0">
              <label :for="`${component.name}Constraints`" class="font-xs">{{ modalParams.constraintParentLabel }}</label>
              <FormMultiSelect :id="`${component.name}Constraints`" componentNameSuffix="1" role="constraint" :items="state.constraint.parent.list" :title.sync="state.constraint.parent.title" :styles="{ height: '125px' }"
                               :onChange="onParentChange" :disabled="!state.loaded"/>
            </div>
          </div>
          <div class="part" :class="computedColClass" v-if="state.constraint.parent.title">
            <div class="form-group mb-0">
              <label :for="`${component.name}Constraints`" class="font-xs">{{ modalParams.constraintChildLabel }}</label>
              <FormMultiSelect :id="`${component.name}Constraints`" componentNameSuffix="2" role="constraint" :items="state.constraint.child.list" :title.sync="state.constraint.child.title" :styles="{ height: '125px' }"
                               :onChange="onChildChange" :disabled="!state.loaded" :key="state.constraint.child.key"/>
            </div>
          </div>
          <div class="part detail" :class="computedColClass" v-if="computedItem">
            <div class="form-group">
              <label :for="`${component.name}Max`" class="font-xs">최대 인원</label>
              <div class="side">제한 없음: 0</div>
              <Number :id="`${component.name}Max`" class="border-focus-purple" :value.sync="computedItem.max" :allowZero="true" :noComma="true" :maxlength="4" v-if="state.loaded && state.constraint.child.value"/>
              <input type="text" class="form-control" disabled v-else>
            </div>
            <div class="form-group form-box">
              <span class="font-xs">중복 가능 여부</span>
              <label class="switch" :for="`${component.name}DuplicateFlag`" @click="ChangeDuplicateFlag()">
                <input type="checkbox" :checked="computedItem.duplicateFlag === 'Y'">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="action">
      <button class="btn btn-purple btn-block" type="submit">저장하기</button>
    </div>
  </form>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import FormMultiSelect from "@/components/FormMultiSelect";
import optionTypes from "@/texts/formItemOptionTypes.json";
import Number from "@/components/Number";
import http from "@/scripts/http";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "modalFormConstraints";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Number, FormMultiSelect},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "xm"});

      state.loaded = false;
      http.get(`/api/admin/forms/${modalParams.formId}/constraints`).then(({data}) => {
        state.loaded = true;
        const constraints = data.body;
        state.constraint.parent.list = constraints.filter(c => !c.parentId);

        for (let i in state.constraint.parent.list) {
          const parent = state.constraint.parent.list[i];
          parent.children = [];

          constraints.filter(c1 => c1.parentId > 0).forEach((c2) => {
            c2.parentId === parent.id && parent.children.push(c2);
          });
        }
      });
    });

    const state = reactive({
      loaded: false,
      constraint: {
        parent: {
          title: "",
          value: null,
          list: [],
        },
        child: {
          title: "",
          value: null,
          list: [],
          key: 0
        }
      },
    });

    const modalParams = store.getters.modalParams(component);

    const computedItem = computed(() => {
      if (state.constraint.child.list.length) {
        return state.constraint.child.list.find(c => c.delFlag === "N" && c.title === state.constraint.child.title);
      }

      return null;
    });

    const computedColClass = computed(() => {
      let num = 6;

      if (!state.constraint.parent.title) {
        num = 12;
      } else if (computedItem.value) {
        num = 4;
      }

      return "col-lg-" + num;
    });

    const onParentChange = () => {
      const parent = state.constraint.parent.list.find(c => c.delFlag === "N" && c.title === state.constraint.parent.title);

      state.constraint.child.title = "";
      state.constraint.child.value = null;
      state.constraint.child.key += 1;

      if (parent) {
        state.constraint.parent.value = parent;
        state.constraint.child.list = parent.children.filter(c => c.delFlag === "N");
      } else {
        state.constraint.parent.value = null;
        state.constraint.child.list = [];
      }
    };

    const onChildChange = () => {
      state.constraint.parent.value.children = state.constraint.child.list;
      state.constraint.child.value = state.constraint.parent.value.children.find(c => c.title === state.constraint.child.title);
    };

    const getRequestArray = (value) => {
      const result = lib.getRenewed(value);

      for (let i1 in result) {
        const parent = result[i1];
        const children = parent.children;
        parent.max = window.Number(parent.max) || 0;

        if (Array.isArray(children) && children.length) {
          for (let i2 in children) {
            children[i2].max = window.Number(children[i2].max) || 0;
          }
        }
      }

      return result;
    };

    const ChangeDuplicateFlag = () => {
      computedItem.value.duplicateFlag === "Y" ? computedItem.value.duplicateFlag = "N" : computedItem.value.duplicateFlag = "Y";
    };

    const submit = () => {
      const args = getRequestArray(state.constraint.parent.list);

      http.put(`/api/admin/forms/${modalParams.formId}/constraints`, args).then(() => {
        store.commit("setSwingMessage", "항목을 저장하였습니다.");
        store.commit("closeModal", {
          name: component.name,
          onClose(modal) {
            store.dispatch("callback", {modal});
          }
        });
      });
    };

    return {component, state, modalParams, optionTypes, computedItem, computedColClass, onParentChange, onChildChange, ChangeDuplicateFlag, submit,};
  }
});
</script>

<style lang="scss" scoped>
.form-constraints {
  > .wrapper {
    background: #fff;
    padding: $px25;
  }

  .head {
    position: relative;
    margin-bottom: $px20;

    .title {
      font-size: $px18;
    }
  }

  .body {
    > .row {
      height: 200px;
      overflow: hidden;

      > .part {
        transition: max-width 0.25s, flex 0.25s;

        .form-group {
          position: relative;
          font-size: 0;

          input, select, textarea {
            font-size: $px13;
            transition: none;

            &:not([disabled]) {
              background-color: #fff;
            }
          }

          > .side {
            position: absolute;
            top: 0;
            right: 0;
            font-size: $px11;
          }

          &.form-box {
            border: $px1 solid $colorBorder;
            border-radius: $px4;
            padding: $px10;
            height: $formHeight;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .switch {
              position: relative;
              display: inline-block;
              width: $px50;
              height: $px21;
              margin-bottom: 0;

              > input {
                opacity: 0;
                width: 0;
                height: 0;

                &:checked + .slider {
                  background-color: $colorPurple;

                  &::before {
                    transform: translate($px29, -50%);
                  }
                }

                &:focus + .slider {
                  box-shadow: 0 0 $px1 #2196F3;
                }
              }

              > .slider {
                cursor: pointer;
                background-color: #ccc;
                display: inline-block;
                position: relative;
                width: 100%;
                height: 100%;
                transition: .4s;

                &.round {
                  border-radius: $px50;

                  &::before {
                    border-radius: 50%;
                  }
                }

                &::before {
                  position: absolute;
                  content: "";
                  height: $px13;
                  width: $px13;
                  left: $px4;
                  top: 50%;
                  transform: translateY(-50%);
                  background-color: #fff;
                  transition: 0.18s;
                }
              }
            }
          }
        }

        &.detail {
          > .form-group:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .action {
    position: sticky;
    bottom: 0;

    .btn {
      padding: $px15;
      height: $px60;
      border-radius: 0;
      font-size: $px13;
    }
  }

  @media(max-width: 991px) {
    .body > .row {
      height: auto;

      > div.part {
        padding-bottom: 1.5rem;
      }
    }
  }
}
</style>